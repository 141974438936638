<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('course_transfer_evaluation')"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('course_transfer_evaluation')"
                              :isFilter="false"/>
            </template>
            <div class="row mb-5 align-items-center" v-if="data!=null">
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('student_number')">
                        {{ data.student_number }}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('name_surname')">
                        {{ data.name }} {{ data.surname }}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('program')">
                        {{ data.program_name }}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('class')">
                        {{ data.class_name }}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('registration_type')">
                        {{ data.registration_type }}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('number_of_semesters')">
                        {{ data.number_of_semesters }}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('cgpa')">
                        {{ data.cgpa }}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('calculated_cgpa')">
                        {{ data.calculated_cgpa }}
                    </b-form-group>
                </div>
                <div class="col-12" v-if="data && files.length>0">
                    <h6 class="mb-4">{{ $t('documents').toUpper() }}</h6>
                    <table class="table table-bordered w-auto">
                        <tr>
                            <th>{{ $t('university').toUpper() }}</th>
                            <th>{{ $t('country').toUpper() }}</th>
                            <th>{{ $t('files').toUpper() }}</th>
                        </tr>
                        <tr v-for="file in files" v-bind:key="file.id">
                            <td>{{ file.university.name }}</td>
                            <td>{{ file.university.country_name }}</td>
                            <td v-if="file.university_id!=data.university_id">
                                <a href="javascript:void(0)" style="margin-right: 1em" @click="download(ffile)"
                                   v-for="ffile in file.files" v-bind:key="ffile.id">{{ ffile.file_name }}</a>
                            </td>
                            <td v-else-if="file.university_id==data.university_id && data.all_programs">
                                <a href="javascript:void(0)" style="margin-right: 1em"
                                   @click="downloadTranscript(program.id)"
                                   v-for="program in data.all_programs" v-bind:key="program.id">{{
                                        program.program.name
                                    }}</a>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div v-if="slots!=null">
                <h6 class="mb-4">{{ $t('courses').toUpper() }}</h6>
                <table class="table table-bordered">
                    <tr>
                        <th>{{ $t('slot_type').toUpper() }}</th>
                        <th class="width-100">{{ $t('course_code').toUpper() }}</th>
                        <th>{{ $t('course_name').toUpper() + ' / ' + $t('elective_pool').toUpper() }}</th>
                        <th class="w-40">{{ $t('credit').toUpper() }}</th>
                        <th class="w-40">{{ $t('semester').toUpper() }}</th>
                        <th class="w-40">{{ $t('grade').toUpper() }}</th>
                        <th class="w-40" v-if="allowEdit"></th>
                        <th style="width: 50%;text-align: center">{{ $t('added_course_transfer').toUpper() }}</th>
                    </tr>

                    <tr v-for="slot in slots" v-bind:key="slot.id">
                        <td>{{ slot.slotType ? slot.slotType.name : "" }}</td>
                        <td>{{ slot.course ? slot.course.code : '' }}</td>
                        <td>{{
                                slot.course ? slot.course.name : (slot.pool ? slot.pool.name : '')
                            }}
                        </td>
                        <td>{{ slot.credit }}</td>
                        <td>{{ slot.semester }}</td>
                        <td><span v-if="slot.last_course">{{ slot.last_course.letter_grade }}</span></td>
                        <td v-if="allowEdit">
                            <a v-if="!slot.last_course || data.cgpa<2 || parseFloat(slot.last_course.point)<2"
                                role="button" class="ri-add-circle-line ri-2x"
                                                @click="addCourse(slot)"></a>
                        </td>
                        <td>
                            <div v-if="transferredCourses!=null && transferredCourses[slot.id]">
                                <p v-for="course in transferredCourses[slot.id]" v-bind:key="course.id">
                                <span v-if="course.university_course">
                                <b>{{ $t('country') }}:</b> {{ course.university_course.country_name }}  <b>{{
                                        $t('university')
                                    }}:</b> {{ course.university_course.university_name }}<br>
                                <b>{{ $t('course_code') }}:</b> {{ course.university_course.code }} <b>{{ $t('course_name') }}:</b> {{
                                        course.university_course.name
                                    }} <b>{{ $t('grade') }}:</b> {{
                                        course.letter_grade
                                    }} ({{ course.converted_letter_grade }})
                                </span>
                                <span v-if="course.course">
                                <b>{{ $t('course_code') }}:</b> {{ course.course.code }} <b>{{ $t('course_name') }}:</b> {{
                                            course.course.name
                                        }} <b>{{ $t('grade') }}:</b> {{
                                            course.letter_grade
                                        }}
                                </span>

                                    <a v-if="allowEdit" role="button" class="text-danger btn-link btn-xs"
                                       @click="deleteCourse(course.id)">
                                        {{ $t('delete').toUpper() }}
                                    </a>
                                </p>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="mb-3 d-inline-block"></div>
            <div v-if="data && !data.read_only && data.step.id">
                <h6 class="text-uppercase mb-4">{{ $t('approval_procedures').toUpper() }}</h6>
                <ValidationObserver ref="updateForm">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
                            <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('status')">
                                    <template>
                                        <div>
                                            <multiselect
                                                placeholder="Yok"
                                                v-model="updateForm.status"
                                                :options="computeStatusOptions"
                                                :custom-label="statusOptionsCustomLabel"
                                                :select-label="''"
                                                :selected-label="''"
                                                :deselect-label="''"
                                                :searchable="false">
                                            </multiselect>
                                            <div class="invalid-feedback d-block" v-if="errors[0]"
                                                 v-html="errors[0]"></div>
                                        </div>
                                    </template>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-9">
                            <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('explanation')">
                                    <b-form-textarea no-resize rows="4" class="h-40 h-auto-sm" maxlength="100"
                                                     v-model="updateForm.reason"></b-form-textarea>
                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6" v-if="updateForm.status=='waiting_info'">
                            <div class="row">
                                <div class="col-12">
                                    <b-form-group :label="$t('from_where')">
                                        <multi-selectbox :multiple="false"
                                                         v-model="updateForm.info_from"
                                        :options="[{value:'unit', text: $t('unit')}, {value:'program', text: $t('program')}]"></multi-selectbox>
                                    </b-form-group>
                                </div>
                                <div class="col-12" v-if="updateForm.info_from=='unit'">
                                    <ValidationProvider name="unit_ids" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('units')">
                                            <units-selectbox v-model="updateForm.unit_ids"
                                                             :validate-error="errors[0]"></units-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12" v-if="updateForm.info_from=='program'">
                                    <div class="row">
                                        <div class="col-12">
                                            <b-form-group :label="$t('faculty')">
                                                <faculty-selectbox :multiple="true" v-model="updateForm.faculty_code"></faculty-selectbox>
                                            </b-form-group>
                                        </div>
                                        <div class="col-12">
                                            <ValidationProvider name="program_code" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('program')">
                                                    <program-selectbox :multiple="true" :is_faculty_code_required="true"
                                                                       :faculty_code="updateForm.faculty_code"
                                                                       v-model="updateForm.program_codes"
                                                                     :validate-error="errors[0]"></program-selectbox>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-form-group>
                        <b-button variant="primary" @click="updateStatus">{{
                                $t('update').toUpper()
                            }}
                        </b-button>
                    </b-form-group>
                </ValidationObserver>
            </div>
            <div class="row mb-3" v-if="data && data.status=='pending' && checkPermission('coursetransfer_complete')">
                <div class="col-12">
                    <b-button variant="primary" @click="complete">{{
                            $t('complete').toUpper()
                        }}
                    </b-button>
                </div>
            </div>
            <div v-if="data!=null && data.steps && data.steps.length">
                <b-table
                    :empty-text="$t('there_are_no_records')"
                    bordered
                    striped
                    responsive
                    :items="data.steps"
                    :fields="[
                            { key:'rank', label: this.toUpperCase('rank'), thClass: 'text-center',  tdClass: 'text-center'},
                            { key:'approvers', label: this.toUpperCase('can_approvers'), thClass: 'text-center'},
                            { key:'approved', label: this.toUpperCase('approved_by'), thClass: 'text-center'},
                            { key:'status', label: this.toUpperCase('status'), thClass: 'text-center'},
                            { key:'reason', label: this.toUpperCase('explanation'), thClass: 'text-center'}
                        ]"
                    show-empty
                    sort-by="rank"
                    class="mb-3 table-dropdown no-scrollbar border rounded">
                    <template #cell(approvers)="row">
                            <span v-for="(approver,i) in row.item.approvers" :key="i">
                                {{
                                    typeof approver.surname != 'undefined' ? (approver.name + ' ' + approver.surname) : approver.explanation
                                }} <br>
                            </span>
                    </template>
                    <template #cell(approved)="row">
                            <span v-for="(approver,i) in row.item.approved" :key="i">
                                {{ approver.name }} {{ approver.surname }}<br>
                            </span>
                    </template>
                    <template #cell(status)="row">
                        {{ row.item.status_name }}
                    </template>
                </b-table>
            </div>

            <CommonModal ref="courseModal" size="lg" v-on:onHidden="onCourseFormHidden">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_course') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="courseForm">
                        <div class="row">
                            <div class="col-12">
                                <ValidationProvider name="university_id" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('university')">
                                        <b-form-select v-model="form.university_id" :options="universities">

                                        </b-form-select>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12" v-if="data && form.university_id && form.university_id != data.university_id">
                                <ValidationProvider name="grade_conversion_id" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('grade_scala') }}</label>
                                        <multi-selectbox
                                            :multiple="false"
                                            v-model="form.grade_conversion_id"
                                            :options="gradeConversionOptions"></multi-selectbox>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12" v-if="data && form.university_id && form.university_id != data.university_id">
                                <ValidationProvider name="university_course_id" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group :label="$t('course')">
                                        <div class="d-flex custom-file-upload">
                                            <vue-autosuggest
                                                class=" w-100"
                                                ref="autosuggest"
                                                v-model="courseQuery"
                                                :suggestions="suggestions"
                                                :inputProps="inputProps"
                                                :sectionConfigs="sectionConfigs"
                                                :getSuggestionValue="getSuggestionValue"
                                                @input="fetchResults">
                                                <template v-slot="{ suggestion }">
                                                    <div>{{
                                                            suggestion.item.code + ' - ' + suggestion.item.name
                                                        }}
                                                    </div>
                                                </template>
                                            </vue-autosuggest>
                                            <b-button variant="link" @click="$bvModal.show('newCourseModal')"
                                                      style="white-space: nowrap">
                                                {{ $t('add_course') }}
                                            </b-button>
                                            <input type="hidden" v-model="form.university_course_id"/>
                                        </div>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12" v-if="data && form.university_id && form.university_id == data.university_id">
                                <ValidationProvider name="course_id" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group :label="$t('course')">
                                        <course-auto-complete v-model="form.course_id"
                                                              v-on:courseSelected="courseSelected"
                                                              :validateError="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="data && form.university_id && form.university_id != data.university_id">
                                <ValidationProvider name="letter_grade" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('grade') }}</label>
                                        <b-form-input

                                            v-model="form.letter_grade"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="data && form.university_id && form.university_id == data.university_id">
                                <ValidationProvider name="letter_grade" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('grade') }}</label>
                                        <grade-selectbox :grading_system_id="form.grading_system_id"
                                                         value-type="grade"
                                                         v-model="form.letter_grade"
                                                         :validateError="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="credit" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('credit') }}</label>
                                        <b-form-input
                                            type="number"
                                            v-model="form.credit"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="semester_id" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('semester') }}</label>
                                        <semesters-selectbox
                                            :setActive="true"
                                            v-model="form.semester_id"></semesters-selectbox>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveCourse" variant="primary">{{
                                        $t('save')
                                    }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal id="newCourseModal" size="xl" :onHideOnlyX="true" :footer="false"
                         :footer-class="'justify-content-center'">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_new_course').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="formNewCourse">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('course_name')">
                                            <b-form-input type="text"
                                                          v-model="formNewCourse.name"
                                                          :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="name_tr" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('course_name') + '('+$t('turkish')+')'">
                                            <b-form-input type="text"
                                                          v-model="formNewCourse.name_tr"
                                                          :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="code" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('course_code')">
                                            <b-form-input type="text"
                                                          v-model="formNewCourse.code"
                                                          :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-8">
                                    <ValidationProvider name="university_id" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('university_where_the_course_was_taken')">
                                            <b-form-select v-model="formNewCourse.university_id"
                                                           :options="universities"
                                                           :class="errors[0] ? 'is-invalid':''"
                                            >
                                            </b-form-select>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="language" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('course_language')">
                                            <parameter-selectbox code="languages" v-model="formNewCourse.language"
                                                                :validate-error="errors[0]"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <b-form-group>
                                        <b-button variant="primary" @click="saveNewCourse">{{ $t('save') }}</b-button>
                                    </b-form-group>
                                </div>
                            </div>
                        </ValidationObserver>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Component
import CommonModal from "@/components/elements/CommonModal";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import UnitsSelectbox from "@/components/interactive-fields/UnitsSelectbox";

// Services
import ApprovalStepService from "@/services/ApprovalStepService";
import StudentSlotService from "@/services/StudentSlotService";
import CourseTransferService from "@/services/CourseTransferService";
import UniversityService from "@/services/UniversityService";

// Other
import {ValidationObserver, ValidationProvider} from "vee-validate";
import UniversityCourseService from "@/services/UniversityCourseService";
import StudentDocumentService from "@/services/StudentDocumentService";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";
import GradeSelectbox from "@/components/interactive-fields/GradeSelectbox";
import ProgramSelectbox from "../../../components/interactive-fields/ProgramSelectbox";
import FacultySelectbox from "../../../components/interactive-fields/FacultySelectbox";
import ParameterSelectbox from "../../../components/interactive-fields/ParameterSelectbox";


export default {
    components: {
        ParameterSelectbox,
        FacultySelectbox,
        ProgramSelectbox,
        GradeSelectbox,
        CourseAutoComplete,
        MultiSelectbox,
        AppLayout,
        Header,
        HeaderMobile,

        CommonModal,
        SemestersSelectbox,
        UnitsSelectbox,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            id: this.$route.params.id,
            form: {},
            formNewCourse: {},
            updateForm: {},
            data: null,
            slots: null,
            transferredCourses: null,
            files: [],
            universities: [{text: this.translate('select'), value: 0}],
            debounceMilliseconds: 200,
            inputProps: {
                id: "autosuggest__input",
                placeholder: this.$t('search') + "...",
                class: "form-control"
            },
            courseQuery: "",
            courses: [],
            sectionConfigs: {
                limit: 10,
                default: {
                    onSelected: (item) => {
                        this.form.university_course_id = item.item.id;
                    }
                }
            },
            selectedSlot: null,
            gradeConversionOptions: [],
            allowEdit: false,
            selectedUniversity: null,
            selectedGradeConversion: null
        }
    },
    metaInfo() {
        return {
            title: this.$t("course_transfer_evaluation")
        }
    },
    created() {
        this.loadData();
        this.loadFiles();
    },
    methods: {
        loadData(onlyData = false) {
            CourseTransferService.get(this.id)
                .then(response => {
                    this.data = response.data.data;
                    this.allowEdit = !['approved', 'declined'].includes(this.data.status)
                    if (this.data.step) {
                        this.updateForm = {
                            id: this.data.step.id,
                            status: this.data.step.status,
                            reason: this.data.step.reason,
                        };
                    }
                    if (!onlyData) {
                        this.loadSlots();
                    }
                }).catch(error => {
                if (error.data.message) {
                    this.$toast.error(this.$t('api.' + error.data.message));
                }
            });
        },
        loadSlots() {
            StudentSlotService.getByStudentProgramId(this.data.student_program_id, {params: {with_last_course: true}})
                .then(response => {
                    this.slots = response.data.data;
                    this.loadCourses();
                }).catch(error => {
                if (error.data.message) {
                    this.$toast.error(this.$t('api.' + error.data.message));
                }
            });
        },
        loadCourses() {
            CourseTransferService.getCourses(this.id)
                .then(response => {
                    this.transferredCourses = this.groupBy(response.data.data, 'student_slot_id');
                }).catch(error => {
                if (error.data.message) {
                    this.$toast.error(this.$t('api.' + error.data.message));
                }
            });
        },
        loadFiles() {
            this.files = [];
            CourseTransferService.getFiles(this.id)
                .then(response => {
                    response.data.data.forEach(row => {
                        this.universities.push({
                            value: row.university.id,
                            text: row.university.name
                        });
                        CourseTransferService.getFile(row.id).then(fr => {
                            this.files.push(fr.data.data);
                        })

                        if(this.universities.length==1){
                            this.form.university_id = this.universities[0].value;
                            this.formNewCourse.university_id = this.universities[0].value;
                            this.selectedUniversity = this.universities[0].value;
                        }
                    });
                }).catch(error => {
                if (error.data.message) {
                    this.$toast.error(this.$t('api.' + error.data.message));
                }
            });
        },
        download(file) {
            CourseTransferService.download(file.uuid)
                .then(response => {
                    this._downloadFile(response, file.file_name);
                })
                .catch(error => {
                    if (error.status == 422) {
                        this.$toast.error(error.data.errors.file[0]);
                    }
                    if (error.status == 406) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
        },
        downloadTranscript(id) {
            StudentDocumentService.downloadTranscript(id, this.$i18n.locale)
                .then(response => {
                    this._downloadFile(response, this.data.student_number + '-' + this.$t('file_name_transcript') + '.pdf')
                })
                .catch(e => {
                    this.showErrors(e, null, true)
                })
        },
        loadGradeConversions() {
            this.gradeConversionOptions = [];
            if (!this.form.university_id) {
                return;
            }
            this.selectedGradeConversion = null;
            UniversityService.getUniversityGradeConversions(this.form.university_id)
                .then(response => {
                    response.data.data.forEach(item => {
                        this.gradeConversionOptions.push({
                            value: item.grade_conversion_id,
                            text: item.grade_conversion_name
                        });
                    });
                    if(this.gradeConversionOptions.length==1){
                        this.selectedGradeConversion = this.gradeConversionOptions[0].value;
                        this.form.grade_conversion_id = this.gradeConversionOptions[0].value;
                    }
                    if (this.gradeConversionOptions.length === 0) {
                        this.$toast.error(this.$t('cannot_find_grade_conversion'));
                    }
                }).catch(error => {
                if (error.data.message) {
                    this.$toast.error(this.$t('api.' + error.data.message));
                }
            });
        },
        async store() {
            if (this.checkPermission('coursetransfer_store')) {
                const isValid = await this.$refs.storeForm.validate();
                if (isValid) {
                    CourseTransferService.store(this.form)
                        .then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.form = response.data.data;
                        })
                        .catch(e => {
                            if (e.data.message) {
                                this.$toast.error(this.$t('api.' + e.data.message));
                            }
                        });
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },
        complete(){
            this.$swal.fire({
                text: this.$t('are_you_sure_you_want_to_complete_course_transfer'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no'),
            }).then((result) => {
                if (result.isConfirmed) {
                    CourseTransferService.complete(this.data.id)
                        .then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(error => {
                            this.showErrors(error);
                        });
                }
            })
        },
        onCourseFormHidden() {
            this.form = {
                university_id: this.selectedUniversity,
                grade_conversion_id: this.selectedGradeConversion,
            };
            this.courseQuery = "";
            this.courses = [];
        },
        addCourse(slot) {
            this.selectedSlot = slot;
            this.$refs.courseModal.$refs.commonModal.show()
        },
        async saveCourse() {
            if (this.checkPermission('coursetransfercourse_store')) {
                const isValid = await this.$refs.courseForm.validate();

                let data = {
                    course_transfer_id: this.id,
                    student_slot_id: this.selectedSlot.id,
                    university_course_id: this.form.university_course_id,
                    course_id: this.form.course_id,
                    semester_id: this.form.semester_id,
                    credit: this.form.credit,
                    letter_grade: this.form.letter_grade,
                    grade_conversion_id: this.form.grade_conversion_id
                };
                if (isValid) {
                    CourseTransferService.addCourse(data).then(response => {
                        this.loadCourses();
                        this.loadData(true);
                        this.form = {
                            university_id: this.selectedUniversity,
                            grade_conversion_id: this.selectedGradeConversion
                        };
                        this.$refs.courseForm.reset();
                        this.selectedSlot = null;
                        this.$refs.courseModal.$refs.commonModal.hide();
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                        .catch(e => {
                            if (e.status !== undefined && e.data.message) {
                                this.$toast.error(this.$t('api.' + e.data.message));
                            }
                        });
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },
        deleteCourse(id) {
            if (this.checkPermission('coursetransfercourse_delete')) {
                this.deleteModal(() => {
                    CourseTransferService.deleteCourse(id)
                        .then(response => {
                            this.loadCourses();
                            this.loadData(true);
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(error => {
                            this.$toast.error(this.$t('api.' + error.data.message));
                        });
                });
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },
        getSuggestionValue(suggestion) {
            let {name, item} = suggestion;
            return item.code + ' - ' + item.name;
        },
        fetchResults() {
            const query = this.courseQuery;
            if (query.length < 3 || !this.form.university_id) {
                return;
            }
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                const config = {
                    params: {
                        key: query,
                        university_id: this.form.university_id
                    }
                };
                UniversityCourseService.search(config).then((response) => {
                    this.courses = response.data.data;
                });

            }, this.debounceMilliseconds);
        },
        async updateStatus() {
            if (this.checkPermission('approvalstep_updatestatus')) {
                const isValid = await this.$refs.updateForm.validate();
                if (isValid) {
                    this.$swal.fire({
                        text: this.$t('are_you_sure_you_want_to_update_the_approval_status'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no'),
                    }).then((result) => {
                        if (result.isConfirmed) {
                            ApprovalStepService.update(this.updateForm.id, this.updateForm)
                                .then(response => {
                                    this.$toast.success(this.$t('api.' + response.data.message));
                                })
                                .catch(error => {
                                    if (error.status == 422) {
                                        if (error.data.errors.status) {
                                            this.$refs.updateForm.errors.status.push(error.data.errors.status[0]);
                                        }
                                        if (error.data.errors.reason) {
                                            this.$refs.updateForm.errors.explanation.push(error.data.errors.reason[0]);
                                        }
                                    }
                                    if (error.status == 406) {
                                        this.$toast.error(this.$t('api.' + error.data.message));
                                    }
                                });
                        }
                    })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },
        statusOptionsCustomLabel(key) {
            let label = key;
            if (this.data.step.statuses[key] != undefined) {
                label = this.data.step.statuses[key].name;
                return label;
            }
            return '';
        },
        async saveNewCourse() {
            if (this.checkPermission('universitycourse_store')) {
                const isValid = await this.$refs.formNewCourse.validate();
                if (isValid) {
                    let formData = {
                        ...this.formNewCourse
                    }
                    UniversityCourseService.store(formData)
                        .then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.$refs.formNewCourse.reset();
                            this.$bvModal.hide('newCourseModal');
                            this.formNewCourse = {university_id: this.selectedUniversity};
                        })
                        .catch(e => {
                            this._showErrors(e)
                        })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },
        courseSelected(course) {

            this.$set(this.form, 'course_id', course.item.course_id);
            this.$set(this.form, 'credit', course.item.credit);
            this.$set(this.form, 'grading_system_id', course.item.grading_system_id);
        },
    },
    computed: {
        suggestions() {
            return [
                {
                    data: this.courses.filter(option => {
                        return option.code + ' - ' + option.name
                    })
                }
            ];
        },
        computeStatusOptions() {
            let options = [];
            if (this.data.step.statuses) {
                for (const item in this.data.step.statuses) {
                    options.push(item);
                }
            }
            return options;
        }
    },
    watch: {
        'form.university_id'() {
            if(this.data.university_id != this.form.university_id){
                this.loadGradeConversions();
            }
            this.selectedUniversity = this.form.university_id;
            this.formNewCourse.university_id = this.selectedUniversity;
        },
        'form.grade_conversion_id'() {
            this.selectedGradeConversion = this.form.grade_conversion_id;
        }
    }
};
</script>
